import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import {
    useForm,
    // Controller
} from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { useState } from 'react'

// Atom
import InputSelect, { InputSelectType } from '../../../../Components/Atoms/InputSelect'

function MModCompraPart(props) {
    const {
        onSubmit,
        infoShow,
        // title,
        name,
        fc_DESCRIPCION,
        fi_PRECIO_PLAN,
        fc_ETIQUETA_PERIODO,
        fc_DESCRIPCION_OFERTA,
        lstPlanContratacionxPlan,
        // fc_TXT_SERVICIO_CONTRATADO,
        // fc_URL_SERVICIO_CONTRATADO,
        // fc_TARGET_SERVICIO_CONTRATADO,
        symbolPrice,
        // fc_TXT_SERVICIO_NO_CONTRATADO,
        // fc_URL_SERVICIO_NO_CONTRATADO,
        // fc_TARGET_SERVICIO_NO_CONTRATADO,
        Caracteristicas,
        fc_TITULO_PROMO,
        caracteristicasPromocion,
    } = props
    const schemaFilter = yup.object().shape({
        contrato: yup.string(),
    });

    const {
        // register,
        handleSubmit,
        // watch, 
        // errors, 
        // getValues,  
        // control,
        // setValue,
        //formState, 
        // unregister 
    } = useForm({
        defaultValues: {
            // minPrice: '',
            // typePlay: [],
            contrato: "",
        },
        resolver: yupResolver(schemaFilter),
    });

    useEffect(() => {
    })

    const changeSelected = (value, url) => {
        console.log('Conoce más', value)
        setdataService(value)
        setdataURL(url)
    }
    console.log("arregloDatos", lstPlanContratacionxPlan, Caracteristicas)

    const searchCharacteristics = (TypeFlag = null, Caracteristicas) => {
        if (TypeFlag === "texto_caracteristica") {
            let texto = ""
            const data = Caracteristicas.length ? Caracteristicas : [];
            // data.filter(function (CaracData) {
            //     if (CaracData.fi_TIPO_VALOR_CARACTERISTICA === 1 && texto === "") {
            //         texto = CaracData.fc_TITULO + ' ' + CaracData.fc_VALOR
            //     }
            //     return CaracData
            // })
            let newArrayCaract = data.filter((item) => item.fi_TIPO_VALOR_CARACTERISTICA !== 5);
            if(newArrayCaract.length){
                texto = newArrayCaract[0].fc_TITULO + ' ' + newArrayCaract[0].fc_VALOR
            }
            console.log("texto_caracteristica", texto)
            return texto
        }
        if (TypeFlag === "Redes") {
            let mmcSocialNet = []
            const data = Caracteristicas.length ? Caracteristicas : [];
            data.filter(function (CaracData) {
                if (CaracData.fc_DESCRIPCION === "Redes Sociales" && mmcSocialNet.length === 0) {
                    mmcSocialNet.Title = CaracData.fc_TITULO
                    mmcSocialNet.Value = CaracData.fc_VALOR
                }
                return CaracData
            })

            return mmcSocialNet
        }
    }
    const [dataService, setdataService] = useState("")
    const [dataURL, setdataURL] = useState("#")

    let texto_caracteristica = searchCharacteristics('texto_caracteristica', Caracteristicas)
    let mmcSocialNet = searchCharacteristics('Redes', Caracteristicas)
    let id_familia = localStorage.getItem('id_familia')
    return (
        <div className="modalClaro modalModCompra">
            <h3 className="h5">¿Elige cómo quieres continuar? </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="modalModCompraOptions">
                    {
                        lstPlanContratacionxPlan?.length
                            ?
                            lstPlanContratacionxPlan.map((planContra, index) => (
                                <>
                                    {
                                        planContra.fc_OPCION_LBX === "SSE" ?
                                            <InputSelect
                                                key={uuid()}
                                                type={InputSelectType.RADIO}
                                                defaultValue="Si"
                                                name="contraro"
                                                checked={dataService === "si" ? true : false}
                                                // {...register('contrato')}
                                                onChange={() => { changeSelected("si", planContra.fc_URL) }}

                                            >
                                                {/* Ya tengo un Servicio Móvil contratado */}
                                                Ya tengo un servicio{id_familia === "SMO" ? ' móvil ':' Claro Hogar '}contratado
                                            </InputSelect>
                                            : null
                                    }
                                    {
                                        planContra.fc_OPCION_LBX === "NSE" ?
                                            <InputSelect
                                                key={uuid()}
                                                type={InputSelectType.RADIO}
                                                defaultValue="No"
                                                name="contraro"
                                                checked={dataService === "no" ? true : false}
                                                // {...register('contrato')}
                                                onChange={() => { changeSelected("no", planContra.fc_URL) }}

                                            >
                                                Aún no tengo un servicio{id_familia === "SMO" ? ' móvil ':' Claro Hogar '}contratado
                                            </InputSelect>
                                            : null
                                    }
                                </>
                            ))
                            : null
                    }
                </fieldset>
                <p className="mmcDesc" style={!dataService || dataService === "no" ? { display: "none" } : { display: "block" }}>Al dar click te solicitaremos tus datos y un asesor se contactará en breve contigo</p>
                <a className="btn btnPrimario" target="_blank" rel="noopener noreferrer" href={dataURL}>Continuar</a>
                {/* <button className="btn btnPrimario" type="submit" url={dataURL} >Continuar</button> */}

            </form>
            <div className="modalModCompraExtra">
                <div className="mmcTop">
                    <p className="mmcTopLeft">
                        <span>Estas solicitando: </span>
                        {
                            name
                                ?
                                <>
                                    <strong style={{"font-weight": "bold"}}>{name} {fc_DESCRIPCION?.length ? fc_DESCRIPCION : null}</strong><br/>
                                    {/* <strong>{fc_DESCRIPCION?.length ? fc_DESCRIPCION : null}</strong> */}
                                </>
                                : null
                        }
                    </p>
                    <p className="mmcTopRight">
                        <span>{fc_DESCRIPCION_OFERTA} {fc_ETIQUETA_PERIODO} </span>
                        <strong>{symbolPrice} {fi_PRECIO_PLAN}</strong>
                    </p>
                </div>
                {
                    texto_caracteristica &&
                    <>
                        <p className="mmcTopExtra">{texto_caracteristica}</p>
                    </>
                }
                <div className="mmcBottom">
                    {
                        mmcSocialNet?.Title
                            ?
                            <div className="mmcBottomCol cLeft">
                                <div className="mmcSocialNet">
                                    <span>{mmcSocialNet.Title}</span>
                                    <ul>
                                        {
                                            mmcSocialNet.Value.split("|").map((icoRed, index) => (
                                                <li key={uuid()}>
                                                    <i className={'ico ' + (icoRed === 'ico-instagram-s' || icoRed === 'ico-snapchat-s' || icoRed === 'ico-telegram-s'
                                                        || icoRed === 'ico-tiktok-s' || icoRed === 'ico-twitter-s' || icoRed === 'ico-waze-s'
                                                        || icoRed === 'ico-whatsapp-s' || icoRed === 'ico-youtube-s'
                                                        ? icoRed.replace('ico-', 'ico-c-') : icoRed)} />
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            : null
                    }
                    {
                        fc_TITULO_PROMO
                            ?
                            <div className="mmcBottomCol cRight">
                                <div className="mmcSocialNet">
                                    <span>{fc_TITULO_PROMO}</span>
                                    <ul className="apps">
                                        {
                                            caracteristicasPromocion?.length
                                                ?
                                                caracteristicasPromocion.map((caract, index) => (
                                                    caract?.fc_TIPO_VALOR_CARACTERISTICA.includes("IMG") ?
                                                        <picture key={uuid()}>
                                                            <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                                                            <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                                                            <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                                                            <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                                                            <img className="lazyload" data-src={caract?.fc_VALOR} alt={caract?.fc_ALT_IMG} width="75" height="25" />
                                                        </picture>
                                                        :
                                                        <li key={uuid()}>
                                                            <i className={caract?.fc_VALOR}></i>
                                                        </li>
                                                ))
                                                : null
                                        }
                                    </ul>
                                </div>
                            </div>
                            : null
                    }
                    {
                        parseInt(infoShow) === 1 &&
                        <>
                            {/* <div className="mmcBottomCol cLeft">
                                <div className="mmcSocialNet">
                                    <span>Redes Sociales Ilimitadas</span>
                                    <ul>
                                        <li><i className="ico-facebook-s"></i></li>
                                        <li><i className="ico-c-instagram-s"></i></li>
                                        <li><i className="ico-facebook-messenger-s"></i></li>
                                        <li><i className="ico-c-waze-s"></i></li>
                                        <li><i className="ico-c-whatsapp-s"></i></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/*  <div className="mmcBottomCol cRight">
                                 <div className="mmcSocialNet">
                                     <span>Aplicaciones Ilimitadas</span>
                                     <ul className="apps">
                                         <li><i className="cIco-uber"></i></li>
                                         <li><i className="cIco-deezer"></i></li>
                                         <li><i className="cIco-spotify"></i></li>
                                     </ul>
                                 </div>
                             </div> */}
                        </>
                    }
                    {
                        parseInt(infoShow) === 2 &&
                        <>
                            <div className="mmcBottomCol cLeft">
                                <div className="mmcList">
                                    <ul>
                                        <li>15 Mbps de Internet</li>
                                        <li>180 digitales + 40 HD canales de Televisión </li>
                                        <li>HBO dos meses sin costo</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mmcBottomCol cRight">
                                <div className="mmcSocialNet">
                                    <span>Suscripción Incluida </span>
                                    <ul className="apps">
                                        <li>
                                            <i className="cIco-claro-video"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                    {
                        parseInt(infoShow) === 3 &&
                        <>
                            <div className="mmcBottomCol cLeft">
                                <div className="mmcList">
                                    <ul>
                                        <li>Canales SD 130 </li>
                                        <li> Canales HD 40</li>
                                        <li>Canales de audio 50</li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
MModCompraPart.defaultProps = {
    onSubmit: () => { },
    infoShow: 1,
}
MModCompraPart.propTypes = {
    onSubmit: PropTypes.func,
    infoShow: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}
export default MModCompraPart
