import './Sass/main.sass'
import ModulosPeopleContainer from "./Containers/People/Modules";
function App() {
  return (
    <div className="App">
      <ModulosPeopleContainer/>
    </div>
  );
}

export default App;
